define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-status", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/string", "discourse/helpers/d-icon", "discourse/lib/decorators", "discourse-i18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _string, _dIcon, _decorators, _discourseI18n, _activityPubActor, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubStatus extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "forComposer", [_tracking.tracked]))();
    #forComposer = (() => (dt7948.i(this, "forComposer"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "ready", [_tracking.tracked]))();
    #ready = (() => (dt7948.i(this, "ready"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "enabled", [_tracking.tracked]))();
    #enabled = (() => (dt7948.i(this, "enabled"), void 0))();
    constructor() {
      super(...arguments);
      this.forComposer = this.args.modelType === "composer";
      const actor = this.findActor();
      if (actor) {
        this.ready = actor.ready;
        this.enabled = actor.enabled;
        this.messageBus.subscribe("/activity-pub", this.handleMessage);
        if (this.forComposer && !this.args.model.activity_pub_visibility) {
          this.args.model.activity_pub_visibility = actor.default_visibility;
        }
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.messageBus.unsubscribe("/activity-pub", this.handleMessage);
    }
    findActor() {
      const category = this.forComposer ? this.args.model.category : this.args.model;
      const tags = this.forComposer ? this.args.model.tags : [this.args.model];
      let actor;
      if (category) {
        actor = _activityPubActor.default.findByModel(category, "category");
      }
      if (!actor && tags) {
        tags.some(tag => {
          if (tag) {
            actor = _activityPubActor.default.findByModel(tag, "tag");
          }
          return !!actor;
        });
      }
      return actor;
    }
    handleMessage(data) {
      const model = data.model;
      if (model && model.type === this.args.modelType && model.id === this.args.model.id) {
        this.enabled = model.enabled;
        this.ready = model.ready;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "handleMessage", [_decorators.bind]))();
    get active() {
      return this.site.activity_pub_enabled && this.enabled && this.ready;
    }
    get translatedTitle() {
      const args = {
        model_type: this.args.modelType
      };
      if (this.active) {
        args.delay_minutes = this.siteSettings.activity_pub_delivery_delay_minutes;
      }
      return (0, _discourseI18n.i18n)(`discourse_activity_pub.status.title.${this.translatedTitleKey}`, args);
    }
    get translatedTitleKey() {
      if (!this.site.activity_pub_enabled) {
        return "plugin_disabled";
      }
      if (this.args.modelType === "category" && this.args.model.read_restricted) {
        return "category_read_restricted";
      }
      if (!this.enabled) {
        return "model_disabled";
      }
      if (!this.ready) {
        return "model_not_ready";
      }
      if (this.active) {
        if (!this.site.activity_pub_publishing_enabled) {
          return "publishing_disabled";
        }
        return "model_active.first_post";
      } else {
        return "model_not_active";
      }
    }
    get statusKey() {
      if (this.active) {
        return !this.site.activity_pub_publishing_enabled ? "publishing_disabled" : "active";
      } else {
        return "not_active";
      }
    }
    get classes() {
      let result = `activity-pub-status ${(0, _string.dasherize)(this.statusKey)}`;
      if (this.args.onClick) {
        result += " clickable";
      }
      return result;
    }
    labelKey(type) {
      let attribute = "status";
      let key = this.statusKey;
      if (this.forComposer && this.site.activity_pub_publishing_enabled) {
        attribute = "visibility";
        key = this.args.model.activity_pub_visibility;
      }
      return `discourse_activity_pub.${attribute}.${type}.${key}`;
    }
    get translatedLabel() {
      return (0, _discourseI18n.i18n)(this.labelKey("label"));
    }
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class={{this.classes}} title={{this.translatedTitle}} role="button">
          {{icon "discourse-activity-pub"}}
          <span class="label">{{this.translatedLabel}}</span>
        </div>
      
    */
    {
      "id": "kaVE9ka/",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"classes\"]]],[15,\"title\",[30,0,[\"translatedTitle\"]]],[14,\"role\",\"button\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse-activity-pub\"],null]],[1,\"\\n      \"],[10,1],[14,0,\"label\"],[12],[1,[30,0,[\"translatedLabel\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-status.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubStatus;
});